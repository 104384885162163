import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import InternalRouter from "./pages/private/internal/InternalRouter";
import AppLoader from "./pages/public/AppLoader";
import SignIn from "./pages/public/SignIn";
import { useAccountContext } from "./contexts/AccountContext";
import { useAuthorizationsContext } from "./contexts/AuthorizationsContext";
import SetPassword from "./pages/public/SetPassword";
import IntermediateRouter from "./pages/private/intermediate/IntermediateRouter";
import ForgottenPassword from "./pages/public/ForgottenPassword";
import MobileCatcher from "./pages/public/MobileCatcher";
import useScreenSize from "./hooks/useScreenSize";
import { useNavigationContext } from "./contexts/NavigationContext";
import InvestorRouter from "./pages/private/investor/InvestorRouter";

function AppRouter() {
  const { accountContext, isIntermediate, isInvestor } = useAccountContext();
  const { authorizationsContext } = useAuthorizationsContext();
  const { isPreproduction } = useNavigationContext();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const isLoggedIn = accountContext && authorizationsContext;

  const privateRouterSwitch = () => {
    if (isIntermediate) {
      return <Route exact path="/home" element={<IntermediateRouter />} />;
    } else if (isInvestor) {
      return <Route exact path="/home" element={<InvestorRouter />} />;
    } else {
      return <Route exact path="/home" element={<InternalRouter />} />;
    }
  };

  useEffect(() => {
    if (screenSize.width < 700) {
      navigate("/mobile-catcher");
    }
  }, [screenSize.width]);

  return (
    <Routes>
      <Route exact path="/mobile-catcher" element={<MobileCatcher />} />
      <Route exact path="/login" element={<SignIn />} />
      <Route
        exact
        path="/forgotten-password/"
        element={<ForgottenPassword />}
      />
      <Route exact path="/password-setup/:token" element={<SetPassword />} />
      {isLoggedIn && privateRouterSwitch()}
      <Route path="*" element={<AppLoader />} />
    </Routes>
  );
}

export default AppRouter;
